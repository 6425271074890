import { Link } from "react-router-dom/cjs/react-router-dom.min"
import MetaGroup from "../../components/meta-group"

export default function Training() {
  return (
    <div>
        <MetaGroup
					title="Software Training"
					metaDescription="Get onsite or virtual training for Rossware Software"
					ogImage="%PUBLIC_URL%/logo512.png"
				/>
      <div className="container py-7">
        <h1 className="text-left mb-4">Training</h1>
        <p>Rossware is proud to partner with <a href="https://clearlearninggroup.com/rossware-training/" className="underline text-primary">Clear Learning Group</a> for virtual and onsite software training with Rossware programs.</p>
        <p>We are able to offer <i>discounted rates</i> on trainings provided by Clear Learning Group when you purchase through us. <Link to="contact" className="underline text-primary">Contact us to get started!</Link></p>

      </div>
    </div>
  )
}