import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Badge } from 'react-bootstrap';
import { FaArrowRight, FaCheckSquare, FaCreditCard, FaDesktop, FaFileContract, FaFileInvoiceDollar, FaLink, FaListAlt, FaMapMarkedAlt, FaMapMarkerAlt, FaMobileAlt, FaMoneyBillWave, FaNewspaper, FaPhoneAlt, FaRobot, FaRoute, FaSignal, FaSort, FaUndoAlt } from 'react-icons/fa';
import MetaGroup from '../../components/meta-group';

class Features extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Appliance Repair Software Features | Rossware"
                    metaDescription="The premier software for any field service business. ServiceDesk is not a mass-market software for mere auxiliary functions, it is a customized operations software, providing the automated nuts and bolts for what you really do, as a service call-oriented office, in delivering service to your customers."
                    ogImage="%PUBLIC_URL%/logo512.png"
                    canonical="features/call-management"
                />
                <div className="py-6">
                    <Container className="container-sm">
                        <div className="text-center">
                            <h1 className="h2">Manage the full lifecycle of the service call</h1>
                            <p className="lead pb-4">From the moment you pick up the phone with a customer to the moment you've been paid and everything in-between, ServiceDesk will help you excel.</p>
                            <div>
                                <Link className="btn btn-primary btn-icon-right mr-5" to="/demo">Book Demo <FaArrowRight /></Link>
                                <Link className="btn btn-light btn-icon-right" to="/contact">Contact Sales <FaArrowRight /></Link>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="py-7 bg-white border-top border-bottom text-center">
                    <Container>
                    <h3 className="h2 text-center mb-6">Before the job</h3>
                        <div className="grid-3-col md-gap">
                            <div>
                                <div className="icon bg-primary mb-4"><FaPhoneAlt /></div>
                                <h5>Call management</h5>
                                <p>Say goodbye to sticky notes. ServiceDesk provides input, tracking, messaging, and associated processing for every incoming call.</p>
                            </div>
                            <div>
                                <div className="icon bg-primary mb-4"><FaLink /></div>
                                <h5>Factory warranty integrations<Badge as={Link} to="/sd-dispatchlinks" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>Automate information flow both ways between the warranty companies and third parties you work with the most. Integrate with ServiceBench, ServiceLive, Dispatch.me, Service Power, LG, Samsung, and pull them directly from emails automatically.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaMapMarkerAlt /></div>
                                <h5>Mapping and dispatch</h5>
                                <p>With a custom on-screen map of your specific service territory you can see the relative geographic locations, and time, for each job, with every technician's route geographically displayed and color coded.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaRoute /></div>
                                <h5>Route Optimization</h5>
                                <p>Optimize a technician's assigned route to be in the most effcient order with the click of a button.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaSort /></div>
                                <h5>Automated schedule optimization</h5>
                                <p>Optimize your entire day with whole roster route optimization. This feature will assign and order jobs in the most effcient manner, even taking into account things like technician start location and lunch times.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaCheckSquare /></div>
                                <h5>Phone number and address verification</h5>
                                <p>Take out the guess work about phone numbers or addresses being valid. With the click of a button you can know for a fact they are.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaDesktop /></div>
                                <h5>Online scheduling<Badge as={Link} to="/sd-cyberoffice" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>As a part of our SD-CyberOffice addon, your customers can access an online scheduler that embeds right in your website. This scheduler not only allows them to schedule a service with you, it shows them live availability for your company.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaUndoAlt /></div>
                                <h5>Recurring service agreements<Badge as={Link} to="/sd-revenuebuilder" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>If you sell and manage your own service contracts, maintenance agreements, or simply want a system that reminds you to perform COD service for particular clients on a periodic basis, this product is designed to meet all such needs.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaListAlt /></div>
                                <h5>Inventory and Parts management</h5>
                                <p>Manage your desired inventory, your current stocked parts, and special order parts for certain jobs.</p>
                            </div>
                        </div>
                    </Container>
                </div>


                <div className="bg-light border-bottom text-center py-7">
                    <Container>
                        <h3 className="text-center h2 mb-5">On the job</h3>
                        <div className="grid-3-col md-gap">
                            <div>
                            <div className="icon bg-primary mb-4"><FaMobileAlt /></div>
                                <h5>Technician mobile app<Badge as={Link} to="/sd-mobile" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>Fully fledged mobile app designed around helping technicians excel. Provides each technician with assigned jobs, mapping to jobs, timeclock features, required parts lists, estimate tools, post visit reporting, part ordering, in field credit card processing, and more.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaRobot /></div>
                                <h5>Robo-calling and SMS <Badge as={Link} to="/sd-cyberoffice" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>Automated systems provide your customers with the ability to confirm appointments and get technician call aheads all with little or no interaction from you.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaMapMarkedAlt /></div>
                                <h5>Customer viewable job and tech tracking<Badge as={Link} to="/sd-cyberoffice" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>Another part of our SD-CyberOffice suite of addon services. This is another online utility which enables your customers to view job tracking and or technician tracking.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaMoneyBillWave /></div>
                                <h5>Over the counter sales<Badge as={Link} to="/sd-dealer" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>Some companies also have stores which customers can enter to purchase machines and parts. This system can fully manage the inventory, sale, and even credit card processing of such an operation.</p>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className="py-7 bg-white text-center">
                    <Container>
                        <h3 className="h2 text-center mb-5">After the job</h3>
                        <div className="grid-3-col md-gap">
                            <div>
                            <div className="icon bg-primary mb-4"><FaNewspaper /></div>
                                <h5>Digital NARDA forms</h5>
                                <p>No more hand writing NARDA forms. ServiceDesk will print your NARDA forms while autofilling all the applicable fields.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaFileInvoiceDollar /></div>
                                <h5>Custom invoicing</h5>
                                <p>Save time and money with our custom digital invoices containing your business's name, logo, and colors that are sent to your customers.</p>
                            </div>
                            <div>
                                <div className="icon bg-primary mb-4"><FaFileContract /></div>
                                <h5>Technician Post Visit Reports<Badge as={Link} to="/sd-mobile" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>When a technician finishes an appointment, they are prompted to fill out the Post Visit Report to indicate status of job, work done descriptions, parts used/orded, etc.</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaCreditCard /></div>
                                <h5>Credit card processing</h5>
                                <p>Our core ServiceDesk program, as well as the technician mobile app <Link to="/sd-mobile">SD-Mobile,</Link> has built in credit card processing. You can utilize one of our select processors to access</p>
                            </div>
                            <div>
                            <div className="icon bg-primary mb-4"><FaSignal /></div>
                                <h5>Positive review generation<Badge as={Link} to="/sd-cyberoffice" variant="primary" className="ml-2 badge-primary">Addon</Badge></h5>
                                <p>When a job is complete and your customer recieves their digital invoice, they can be prompted to customized systems to promote positive review generation on all major review platforms.</p>
                            </div>
                        </div>
                    </Container>
                </div>


            </div>
        );
    }
}

export default Features;