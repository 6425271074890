import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Faq from '../../components/faq';
import ReactSlider from 'react-slider'
import ReactTooltip from 'react-tooltip';
import PricingSchedule from '../../components/pricing-schedule';

export default class Pricing extends React.Component {
	state = {
		monthlyPrice: 150,
		// monthlySavings: 150,
		supportPrice: 25,
		techCount: 1,
	}

	handleTechCount = (value) => {
		let techCount = value + 1;
		let monthlyPrice = (techCount - 1) * 17.5 + 150;
		// let monthlySavings = 325 - monthlyPrice;

		let supportPrice = (techCount - 1) * 2.5 + 25;

		this.setState({
			techCount: techCount,
			monthlyPrice: monthlyPrice.toFixed(2).replace(/[.,]00$/, ""),
			// monthlySavings: monthlySavings.toFixed(2).replace(/[.,]00$/, ""),
			supportPrice: supportPrice.toFixed(2).replace(/[.,]00$/, ""),
		});
	}

	handlePaymentMethod = (event) => {
		if (event.target.value === "1") {
			this.setState({
				directPurchase: false
			});
		} else {
			this.setState({
				directPurchase: true
			});
		}
	}

	toggleModal(modal) {
		this.setState({
			showCyberOfficeModal: modal === 'sdco' ? true : false,
			showHostingModal: modal === 'hosting' ? true : false,
		});
	}

	render() {
		const { monthlyPrice } = this.state;

		return (
			<div>
				<Helmet>
					<title>Rossware | Pricing</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="Rossware pricing - ServiceDesk pricing scales with your business. ServiceDesk addons are ala carte." />
					<meta property="og:title" content="Pricing - Rossware Software, LLC." />
					<meta property="og:image" href="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<ReactTooltip />
				<section className="pt-lg-6 pt-md-4">
					<div className="container-md">
						<div className="pre-text">Pricing</div>
						<h1 className="mb-0">Get the tools your company needs.</h1>
						<h1 className="mb-3">Flexible for any team size.</h1>
						<p className="mb-4">Our pricing is flexible based on the size of your business. Select below how many service technicians your company has to see the monthly price.</p>
						<ReactSlider
							className="horizontal-slider"
							min={0}
							max={10}
							thumbClassName="slider-thumb"
							trackClassName="slider-track"
							renderThumb={(props, state) => <div {...props}><span>{state.valueNow + 1 !== 11 ? state.valueNow + 1 : (state.valueNow + 1) + '+'} <span className="text-white-75 ml-1 font-weight-lighter">{state.valueNow + 1 === 1 ? 'technician' : 'technicians'}</span></span></div>}
							onChange={(value) => this.handleTechCount(value)}
						/>
					</div>
					<div className="container-md mt-4">
						<div className="card pricing-card mb-5">
							<div className="card-body">
								<div className="grid-2-col">
									<div className="pricing-card-body">
										<div className="h2">Core software</div>
										<div>Unlimited access to the core features of our software, ServiceDesk.</div>
										<div className="">
											<div className="h2 mb-0">${monthlyPrice}</div>
											<div className="small text-muted mb-1">Price in USD per month</div>
											<div className="small text-muted mb-3">& includes $100/mo discount for using integrated payments</div>
											<Link to="/contact" className="d-block btn btn-primary">Contact Sales</Link>
										</div>
									</div>
									<div className="plan-checklist-box">
										<div className="flex-list">
											<div className="flex-list-item"><div className="circle-check"></div>Call management</div>
											<div className="flex-list-item"><div className="circle-check"></div>Job management</div>
											<div className="flex-list-item"><div className="circle-check"></div>Scheduling and dispatch</div>
											<div className="flex-list-item"><div className="circle-check"></div>Inventory management</div>
											<Link to="/rosswarepay" className="flex-list-item"><div className="circle-check"></div>Integrated payments</Link>
											<div className="flex-list-item"><div className="circle-check"></div>Reporting</div>
											<div className="flex-list-item"><div className="circle-check"></div>Estimates and invoicing</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<PricingSchedule />
				</section>
				<Faq />


			</div>
		)
	}
}