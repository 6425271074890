import React, { Component } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

class GetDemo extends Component {
    render() {
        return (
            <div className="container">
                <div className="border-top"></div>
                <div className="container-sm py-7">
                    <div className="text-center">
                        <h4 className="text-primary">Checkout what we have to offer</h4>
                        <h2 className="cta-heading">Ready to move your business to the modern age?</h2>
                        <Link className="btn btn-primary mr-4 btn-icon-right mt-4" to="/demo">Book Demo <FaArrowRight /></Link>
                        <Link className="btn btn-light btn-icon-right mt-4" to="/contact">Contact Sales <FaArrowRight /></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetDemo;