import React, { PureComponent } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import RwPayLogo from '../../assets/img/rosswarepay/full_logo.svg'

class RosswarePay extends PureComponent {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Payment Processing - RosswarePay</title>
                    <meta name="fragment" content="!"></meta>
                    <meta name="description" content="RosswarePay is Rossware's integrated payment processing solution." />
                    <meta property="og:title" content="Payment Processing - RosswarePay" />
                    <meta property="og:image" content="path/to/image.jpg" />
                </Helmet>
                <div className="section">
                    <Container className="">
                        <div className="grid-2-col md-gap">
                            <div className="d-flex flex-column justify-content-center align-items-left">
                                <div className="pre-text">Payment Processing</div>
                                <h1 className="h2">Introducing the future of payments</h1>
                                <p className="pb-4">Get competitive rates, world-class support, the newest terminals, and the very best integration with Rossware software.</p>
                                <div>
                                    <Link className="btn btn-primary btn-icon-right mr-5" to="/rosswarepay-signup">Get started now <FaArrowRight /></Link>
                                </div>
                            </div>
                            <img src={RwPayLogo} style={{ marginTop: '70px', minWidth: '300px', minHeight: '50px' }} alt="RosswarePay Logo" />
                        </div>
                    </Container>
                </div>
                <div className="bg-white">
                    <div className="pt-4 pb-4 container">
                        <h3 className="text-center mb-4">All-in-one Integrated Solution</h3>
                        <p className="">We listened to client after client detail the pain and frustration they experience daily, as they deal with currently offered payment processors. They described horrible customer support, glitchy device integration, horrendous rates and unexpected fees on their bills at the end of each month. So we decided to offer a solution to address all of it, introducing RosswarePay.</p>
                    </div>
                </div>
                <div className="section bg-white">
                    <Container>
                        <div className="grid-2-col mb-4 md-gap">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h3>Competitive rates</h3>
                                <p className="">Rossware has worked closely with our payment services provider to provide the best in class payments solution.</p>
                            </div>
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h3>Exceptional Customer Support</h3>
                                <p className="">Support of RosswarePay is provided by the Rossware team you already know and love! Don't spend any more time in frustrating phone trees waiting to be helped.</p>
                            </div>
                        </div>

                        <div className="grid-2-col mb-4 md-gap">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h3>Terminals</h3>
                                <p className="">We are now able to bring you the best in payments terminals, integrated without all the connection glitches you've struggled with through other processors.</p>
                                <p className="">Check out our <a href="https://help.rossware.com/en/articles/731840">help doc on reader support</a> for the most up-to-date information.</p>
                            </div>
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h3>Accounting &amp; reconciliation</h3>
                                <p className="">One huge advantage to running your payments through RosswarePay is having all of your data in one place. Save valuable time with automated reconciliation.</p>
                                <p className="">Additionally, you'll be able to access all your payments information <a href="https://dashboard.rossware.com">right here through your Rossware Dashboard.</a></p>

                            </div>
                        </div>

                        <div className="grid-2-col mb-4 md-gap">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h3>Tips/Gratuities</h3>
                                <p className="">Allow your technicians to collect tips from customers for a job well done!</p>
                            </div>
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h3>Platform fee</h3>
                                <p className="">Charge your clients a percentage of each transaction to help cover your internal costs.</p>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>

        )
    }
}

export default RosswarePay